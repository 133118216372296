@import '../../index';

.container {
  margin-top: 0;
  width: 100vw;
  height: auto;
  min-height: 100dvh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.dark-mode .about-section {
  background-color: rgba(0, 0, 0, 0.2);
}

.about-section {
  width: 100%;
  height: auto; // Changed from 100%
  min-height: 100dvh;
  display: flex;
  gap: clamp(30px, 5vw, 60px);
  justify-content: center;
  align-items: center;
  padding: 20px clamp(5%, 8vw, 15%);
  position: relative;
  box-sizing: border-box;
  // Remove overflow: hidden
  background-color: #171729;
}

.top-left {
  top: 20px;
  left: 20px;
  
  &::before {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
  }
  
  &::after {
    bottom: 0;
    right: 0;
    border-left: none;
    border-top: none;
  }
}

.top-right {
  top: 20px;
  right: 20px;
  
  &::before {
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: none;
  }
  
  &::after {
    bottom: 0;
    left: 0;
    border-right: none;
    border-top: none;
  }
}

.bottom-left {
  bottom: 20px;
  left: 20px;
  
  &::before {
    bottom: 0;
    left: 0;
    border-right: none;
    border-top: none;
  }
  
  &::after {
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: none;
  }
}

.bottom-right {
  bottom: 20px;
  right: 20px;
  
  &::before {
    bottom: 0;
    right: 0;
    border-left: none;
    border-top: none;
  }
  
  &::after {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
  }
}

.video-background {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.content {
  margin-top: clamp(-30px, -3vh, 0px);
  text-align: left;
  z-index: 2;
  position: relative;

  .social_icons {
    display: flex;
    flex-direction: row;
    gap: clamp(10px, 2vw, 20px);
    color: white;
    padding-left: clamp(2px, 1vw, 5px);

    svg {
      font-size: clamp(2rem, 5vw, 5rem);
      filter: drop-shadow(0 0 5px rgba(242, 204, 143, 0.7));
    }
  }

  p {
    font-size: clamp(1.5rem, 5vw, 2.5rem);
    margin: 0 0 clamp(10px, 2vh, 20px);
    font-family: 'Orbitron', sans-serif;
    letter-spacing: 1px;
    color: #f2cc8f;
    font-weight: bold;
    text-shadow: 0 0 5px rgba(230, 223, 223, 0.5);

    &:hover {
      text-shadow: 0 0 10px rgba(230, 223, 223, 0.8);
    }
  }

  h1 {
    font-size: clamp(2.5rem, 8vw, 6rem);
    margin: 0;
    font-family: 'Orbitron', sans-serif;
    letter-spacing: 2px;
    color: #e6dfdf;
    text-shadow: 
      0 0 5px #fff,
      0 0 10px #544eef,
      0 0 15px #521fdc;
    transition: all 0.3s ease;

    &:hover {
      text-shadow: 
        0 0 10px #fff,
        0 0 15px #544eef,
        0 0 20px #521fdc;
    }
  }
}

.image-wrapper {
  z-index: 2;
  position: relative;
  
  img {
    width: clamp(150px, 30vw, 325px);
    height: auto;
    aspect-ratio: 1;
    border-radius: 30%;
    object-fit: cover;
    @include glowing-border-effect();
  }
}

@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    padding: 20px 5%;
    text-align: center;
  }

  .content {
    text-align: center;

    .social_icons {
      justify-content: center;
      
      svg {
        font-size: clamp(1.8rem, 6vw, 3rem);
      }
    }

    p {
      margin-bottom: 60px;
      font-size: clamp(1.2rem, 4vw, 1.8rem);
    }

    h1 {
      margin-top: 50px;
      margin-bottom: 10px;
      font-size: clamp(2rem, 7vw, 3.2rem);
    }
  }

  .image-wrapper img {
    width: clamp(120px, 40vw, 200px);
  }
}

@media (max-width: 480px) {
  .about-section {
    gap: 30px;
    padding: 20px 15px;
  }

  .content p {
    margin-bottom: 40px;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .about-section {
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
}