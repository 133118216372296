.navbar-fixed-top {
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
  
  .MuiToolbar-root {
    padding: 0 20px;
    min-height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .navbar-fixed-top {
    .MuiToolbar-root {
      padding: 0 8px;
      justify-content: flex-start;
    }
  }
  
  .navigation-bar {
    .MuiButton-root {
      font-size: 0.7rem;
      padding: 0 6px 15px;
    }
    
    .MuiSvgIcon-root {
      font-size: 1.2rem;
      margin: 0 8px;
    }
  }
}
.navbar-fixed-top {
  width: 100vw !important;
  max-width: 100vw !important;
  left: 0 !important;
  right: 0 !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
  
  .MuiToolbar-root {
    min-height: 56px;
    padding-left: 8px;
    padding-right: 8px;
    justify-content: space-between;
    width: 100%;
    
    /* Constrain the buttons container */
    > div:last-child {
      max-width: calc(100% - 60px);
      overflow: hidden;
    }
  }
}



/* Navigation items */
.navigation-bar {
  .MuiSvgIcon-root {
    font-size: 1.5rem; 
    transition: all 0.3s ease;
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.7));
    margin: auto 20px;

    &:hover {
      transform: scale(1.1);
      filter: 
        drop-shadow(0 0 5px #fff)
        drop-shadow(0 0 10px #544eef)
        drop-shadow(0 0 15px #521fdc);
    }
  }

  .nav-list-item, 
  .MuiButton-root {
    position: relative;
    transition: all 0.3s ease;
    padding-bottom: 15px;
    color: inherit;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 10px 10px;
    letter-spacing: 2px;
    text-shadow: 
      0 0 1px #fff,               
      0 0 3px #544eef,           
      0 0 5px #521fdc,           
      0 0 10px rgba(130, 88, 240, 0.7); 
  }

  .nav-list-item:hover, 
  .MuiButton-root:hover {
    text-shadow: 
      0 0 3px #fff,
      0 0 5px #544eef,
      0 0 10px #521fdc,
      0 0 15px rgba(130, 88, 240, 0.9);
  }
}

/* Responsive adjustments */
@media (max-width: 1100px) {
  .navigation-bar {
    .MuiSvgIcon-root {
      font-size: 1.1rem;
    }
    
    .nav-list-item, 
    .MuiButton-root {
      font-size: 1.1rem;
      margin: 0 6px;
    }
  }
}

@media (max-width: 900px) {
  .navigation-bar {
    .MuiSvgIcon-root {
      font-size: 0.9rem;
    }
    
    .nav-list-item, 
    .MuiButton-root {
      font-size: 0.7rem;
      margin: 0;
    }
  }
}

@media (max-width: 730px) {
  .navigation-bar {
    .MuiSvgIcon-root {
      font-size: 0.8rem;
    }
    
    .nav-list-item, 
    .MuiButton-root {
      font-size: 0.7rem;
      margin: -15px;
    }
  }
}

/* Underline bar */
.nav-list-item::after, .MuiButton-root::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: #8158f0;
  box-shadow: 
    0 0 3px #ffffffb3,
    0 0 5px #544eef,
    0 0 8px #521fdc;
  transform: translateX(-50%);
  transition: width 0.3s ease;
}

/* Active state */
.nav-list-item.active, .MuiButton-root.active {
  color: #8158f0;
  text-shadow: 
      0 0 1px #fff,               
      0 0 3px #544eef,           
      0 0 5px #521fdc,           
      0 0 10px rgba(130, 88, 240, 0.7); 
}

.nav-list-item.active::after, .MuiButton-root.active::after {
  width: 80%;
  transition: width 0s; 
}

/* Hover state */
.nav-list-item:hover::after, .MuiButton-root:hover::after {
  width: 80%;
  transition: width 0.5s ease;
}

@media (max-width: 1100px) {
  .navigation-bar .nav-list-item, 
  .navigation-bar .MuiButton-root {
    font-size: 1.1rem; 
    margin: 0 6px;
  }
}

@media (max-width: 900px) {
  .navigation-bar .nav-list-item, 
  .navigation-bar .MuiButton-root {
    font-weight: 900;
    font-size: 0.7rem; 
    margin: 0px; 
  }
}

@media (max-width: 730px) {
  .navigation-bar {
    .MuiButton-root {
      margin: 0 5px;
      padding: 0 5px;
      font-size: 0.7rem;
    }
  }
  .MuiSvgIcon-root {
    font-size: 1.5rem !important;
  }
}
.navbar-fixed-top {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  
  .MuiToolbar-root {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
  }
}
.navigation-bar {
  .MuiButton-root {
    min-width: auto; // Remove minimum width constraint
    padding: 0 8px; // Adjust padding for mobile
    
    @media (max-width: 600px) {
      font-size: 0.6rem;
      padding: 0 4px;
      letter-spacing: 1px;
    }
  }
}