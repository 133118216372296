@import '../../index'; 

.projects-container {
    display: flex;
    flex-direction: column;
    padding: 5% 10%;
    text-align: left;
    margin-top: -50px;
}

.project {
    margin-top: -20px;
}
  
.projects-container .projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
}
  
.projects-container .projects-grid .project {
    text-align: left;
}

.project h2 {
    font-family: 'Orbitron', sans-serif;
    color: #f2cc8f;
    text-shadow: 
        0 0 1px #fff,
        0 0 5px #f2cc8f;
}
  
.project h2:hover {
    color: #8158f0;
    text-decoration: underline;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    text-shadow: 
        0 0 1px #fff,
        0 0 5px #8158f0;
}
  
.zoom {
    border-radius: 5px;
    transition: transform .2s;
    margin: 0 auto;
}
  
.zoom:hover {
    transform: scale(1.05);
}

.project-image {
    width: 100%;
    height: 325px;
    object-fit: cover;
    border-radius: 5px;
    @include glowing-border-effect();
    @include float();
}

@media screen {
    @media (max-width: 700px) {
        .projects-container {
            display: block;
            padding-left: 5%;
            padding-right: 5%;
            margin-top: 0;
        }
  
        .projects-container .projects-grid {
            display: block;
        }
      
        .projects-container .projects-grid .project {
            padding-bottom: 5%;
        }

        .project-image {
            height: 150px;
        }
    }
}