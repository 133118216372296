@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

// * {
//   outline: 1px solid red !important;
// }

body, html {
  overflow-x: hidden;
  width: 100%;
}
html {
  &::before, &::after {
    content: '';
    position: fixed;
    width: 2px;
    height: 100vh;
    top: 0;
    background: linear-gradient(
      to bottom, 
      #544eef 0%, 
      #fff 50%, 
      #544eef 100%, 
    );
    box-shadow: 
      0 0 10px #fff,
      0 0 20px #544eef,
      0 0 30px #521fdc;
    z-index: 10;
    pointer-events: none;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}
:root {
  --primary-border: 3px solid #544eef;
  --primary-glow: 
    0 0 15px #544eef,
    0 0 30px #521fdc,
    inset 0 0 20px #544eef;
  --hover-glow: 
    0 0 15px #fff,
    0 0 30px #544eef,
    inset 0 0 20px #544eef;
}

@mixin glowing-border-effect(
  $transition-speed: 0.3s,
  $hover-scale: 1.1
) {
  border: var(--primary-border);
  box-shadow: var(--primary-glow);
  transition: all $transition-speed ease;

  &:hover {
    box-shadow: var(--hover-glow);
    transform: scale($hover-scale);
  }
}

@mixin float(
  $animation-duration: 4s
){
  animation: float $animation-duration ease-in-out infinite;
}


.header {
  position: relative;
  color: #ffffffac;
  background-color: transparent;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 30px;
  width: fit-content;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 700;
  margin-bottom: 80px;
  
  color: #fff;
  text-shadow: 
    0 0 2px #fff,
    0 0 5px #544eef,
    0 0 10px #521fdc;
  
  // Container glow
  box-shadow: 
    0 0 1px #fff,
    inset 0 0 3px #fff,
    0 0 10px #544eef,
    inset 0 0 10px #544eef,
    0 0 20px #521fdc,
    inset 0 0 20px #521fdc;
  
  border: 5px solid #fff;
  border-radius: 15px;
  
  &::before {
    content: '';
    position: absolute;
    top: 90%;
    left: 50%;
    width: calc(180% - 10px);
    height: 70%;
    background: #544eef;
    transform: 
      translateX(-50%)
      perspective(1em) 
      rotateX(40deg) 
      scale(1, 0.35);
    filter: blur(1.5em);
    opacity: 0.7;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 25px;
    box-shadow: inset 0 0 20px #ffffffac;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }

  &:hover {
    animation: glitch-box 1s linear infinite;
    
    &::before {
      animation: glitch-glow 1s linear infinite;
    }
  }
}

  .social_icons a {
    color: #f2cc8f;
    transition: all 0.3s ease-in-out;
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #8158f0;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    &:hover {
      color: #8158f0;
    }
  }

@keyframes glitch-box {
  0% {
    text-shadow: 
      0 0 8px #fff,
      0 0 15px #544eef,
      0 0 20px #521fdc;
    box-shadow: 
      0 0 1px #ffffffac,
      inset 0 0 3px #ffffffac,
      0 0 10px #544eef,
      inset 0 0 10px #544eef,
      0 0 40px #521fdc,
      inset 0 0 40px #521fdc;
    transform: translate(0);
  }
  33% {
    text-shadow: 
      0 0 8px #fff,
      0 0 15px #0000ff,
      0 0 20px #ff00ff;
    box-shadow: 
      0 0 1px #0000ff,
      inset 0 0 3px #ff00ff,
      0 0 20px #00ffff,
      inset 0 0 20px #ff0000,
      0 0 40px #ffff00,
      inset 0 0 40px #521fdc;
    transform: translate(3px, 3px);
  }
  67% {
    text-shadow: 
      0 0 8px #fff,
      0 0 15px #ff00ff,
      0 0 20px #ffff00;
    box-shadow: 
      0 0 1px #ff00ff,
      inset 0 0 3px #ffff00,
      0 0 20px #ff0000,
      inset 0 0 20px #00ffff,
      0 0 40px #544eef,
      inset 0 0 40px #0000ff;
    transform: translate(-3px, -3px);
  }
  100% {
    text-shadow: 
      0 0 8px #fff,
      0 0 15px #544eef,
      0 0 20px #521fdc;
    box-shadow: 
      0 0 1px #ffffffac,
      inset 0 0 3px #ffffffac,
      0 0 20px #544eef,
      inset 0 0 20px #544eef,
      0 0 40px #521fdc,
      inset 0 0 40px #521fdc;
    transform: translate(0);
  }
}

@keyframes glitch-glow {
  0%, 100% {
    background: #544eef;
    filter: blur(1.5em) hue-rotate(0deg);
  }
  33% {
    background: #0000ff;
    filter: blur(1.5em) hue-rotate(90deg);
  }
  67% {
    background: #ff00ff;
    filter: blur(1.5em) hue-rotate(-90deg);
  }
}

body {
  overscroll-behavior: none; 
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem !important;
}
.contact-section {
  overflow: hidden; 
}

.animate__animated.animate__flash{
  --animate-duration: 0.7s;
}

h1{
  color: #544eef;
  font-size: 2.5em;
  font-family: 'Orbitron', sans-serif;
}

@keyframes glitch {
  0% {
    text-shadow: 
      0 0 8px #fff,
      0 0 15px #544eef,
      0 0 20px #521fdc;
    transform: translate(0);
  }
  20% {
    text-shadow: 
      0 0 8px #fff,
      0 0 15px #544eef,
      0 0 20px #521fdc;
    transform: translate(-2px, 2px);
  }
  40% {
    text-shadow: 
      0 0 8px #fff,
      0 0 15px #544eef,
      0 0 20px #521fdc;
    transform: translate(2px, -2px);
  }
  60% {
    text-shadow: 
      0 0 8px #fff,
      0 0 15px #544eef,
      0 0 20px #521fdc;
    transform: translate(2px, 2px);
  }
  80% {
    text-shadow: 
      0 0 8px #fff,
      0 0 15px #544eef,
      0 0 20px #521fdc;
    transform: translate(-2px, -2px);
  }
  100% {
    text-shadow: 
      0 0 8px #fff,
      0 0 15px #544eef,
      0 0 20px #521fdc;
    transform: translate(0);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 1rem !important;
}

.main-container {
  position: relative;
  background-color: #171729;
}

a {
  text-decoration: none;
}

a:hover {
  color: #5000ca;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

p, h2, h3, h4,label, button {
  color: #e6dfdf;
 font-family: "Lato", sans-serif;
}

a {
  color: #3970d6;
  font-family: "Lato", sans-serif;
}

.navigation-bar {
  justify-content: space-between;
  background-color: #222063;

  button {
    font-size: 1.4rem;
    font-family: "Lato", sans-serif;
    text-transform: initial;
    padding: 6px 30px;
  }

  svg {
    cursor: pointer;
  }
}

.navigation-bar-responsive {
  span, p {
    color: #0d1116;
    font-family: "Lato", sans-serif;
  }
}

.container {
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-image {
  width: 100%;
  height: 450px;

  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
}

.mobile-menu-top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: bold;
}

.items-container {
  padding: 5% 10%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MuiInputBase-input {
  color: black !important;
  visibility: visible !important;
  height: auto !important;
}

.light-mode {
  background-color: #c4c4df;

  p, a, h1, h2, h3, h4, h5, label{
    color: #000000 ;
  }

  .navigation-bar {
    background-color: #524ea0;
  }

  button{
    color: #000000 ;
  }

  .title{
    color: #a96a03;
  }

  a:hover {
    color: #70519e;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  }

  .navigation-bar svg {
    color: #0d1116;
  }

  .about-section {
    background-image: url("./assets/images/bg-light.png");
  }

  .skills-container svg {
    color: #0d1116;
  }

  .chip {
    background-color: rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .vertical-timeline::before {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    color: gray;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 7px solid rgba(0, 0, 0, 0.08) !important;
  }

  .flex-chips .chip-title {
    color: #0d1116;
  }
  .vertical-timeline-element-date {
    color: black !important;
  }  
}

@media screen {
  @media (max-width: 768px) {
    .items-container {
      padding: 5% 5%;
    }
  }
}

@media (hover: none) and (pointer: coarse) {
  // Disable all hover effects
  *:hover {
    transform: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}