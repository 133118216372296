.toast {
  position: fixed;
  top: 150px;
  left: 50%;
  z-index: 1000;
  background-color: rgba(62, 61, 61, 0.8);
  color: #fff;
  padding: 30px 60px;
  border-radius: 5px;
  font-size: 1.5em;
  opacity: 0;
  transform: translate(-50%, -20px); /* Initial position */
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.visible {
    opacity: 1;
    transform: translate(-50%, 0); /* Final position */
  }
}