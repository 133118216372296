footer {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;

    svg {
        font-size: 3em!important;
        margin: 0 10px;
    }
} 

@media (max-width: 768px) {
    footer {
        padding-top: 30px;
        padding-bottom: 30px;
        svg{
            font-size: 2em!important;
            margin: 0 5px;
        }
    }
}