@import '../../index'; 

.contact-container {
  padding: 6% 10% 8% 10%;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-title {
  font-size: 2em;
  margin-bottom: 1px;
}

.contact-description {
  color: rgba(255, 255, 255);
  font-size: 1.2em;
  margin-bottom: 10px;
}

.contact-form {
  padding-top: 1px;
  padding-bottom: 40px;
  width: 100%;

  .MuiTextField-root {
    margin-bottom: 16px;
  }

  .MuiInputBase-root {
    color: #fff;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &.Mui-focused {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  .MuiInputLabel-root {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.2rem;
    font-family: 'DomaineDispNar-Medium', sans-serif;
  }

  .MuiOutlinedInput-notchedOutline {
    border: var(--primary-border);
    box-shadow: 
      0 0 5px #544eef,
      0 0 10px #521fdc;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ffffffad;
    box-shadow: 
        0 0 5px #fff,
        0 0 10px #544eef;
  }

  .MuiInputBase-input {
    color: rgb(255, 255, 255)!important;
    opacity: 1;
  }

}

.form-flex {
  display: flex;
  gap: 10px;

  .MuiFormControl-root {
    width: 100%;
  }
}

.body-form {
  width: 100%;
  margin-bottom: 10px;
}

.submit-button.MuiButton-root {
  float: right;
  margin-top: 6px;
  background-color: #544eef;
  color: #fff;
  padding: 10px 30px;
  font-size: 1.2rem;
  text-transform: none;
  @include glowing-border-effect();
}

@media (max-width: 768px) {
  .contact-container {
    padding: 10%;
  }

  .contact-title {
    font-size: 2.5em;
  }

  .form-flex {
    flex-direction: column;
  }
}
